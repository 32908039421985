.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.nclr {background-color: #c43e56 !important;
color: white;text-align: center;
}

.nshare{
  background-color: #c43e56 !important;
  color: white;
  text-align: center;
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.w25{max-width:100px}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.active{color: red!important;}
.nav-link{color:black;}
.sk{border-radius: 20px;}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.alertCont{ cursor: pointer;}