@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7 !important;
  font-size: 14px !important;
}

.navbar-bg {
  background: #26262e;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.12em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.rclass {
  padding-left: 20%;
  background-color: seashell;
  border: 1px solid;
  border-bottom: none;
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  /* justify-content: center; */
  /* width: 32em; */
  max-width: 100%;
  padding: 2.25em;
  border: none;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0 1.6em;
  color: #545454;
  font-size: 1em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}
.recharts-layer .recharts-pie-label-text,
.recharts-layer .recharts-pie-label-line {
  display: none;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #aaa;
  color: #fff;
  font-size: 14px;
  padding: 5px 16px;
}
.w_90 {
  width: 90% !important;
}
.redi_7 {
  border-radius: 7px;
}
.navbar-text {
  display: inline-block;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.margin_minus_bond {
  margin-top: -25px;
}
.nav-link {
  display: block;
  padding: 0 1rem !important;
}

.navbar-brand img {
  width: 185px;
}
.current_class {
  background: #ffd6d6;
  border-radius: 7px;
}
.current_class p {
  color: #510000;
  font-size: 15px;
}
.navbar .nav-item {
  padding: 0 2rem;
}

.navbar .nav-item .nav-link {
  display: inline-grid;
  text-align: center;
  color: #fff;
}

.navbar {
  padding: 4px 1rem !important;
}

.navbar .nav-item .nav-link span svg {
  fill: #fff;
}

.navbar .nav-item .active {
  color: #c43e56;
}

.navbar .nav-item .active span svg {
  fill: #c43e56;
}

.summarys_tabs li {
  width: 33%;
}
.summaryTopPosition .summarys_tabs li a.active {
  color: #585858 !important;
  background-color: #ffd6d6 !important;
}
.relative {
  position: relative;
}
.span_absolute {
  position: absolute;
  left: 283px;
  top: -61px;
  color: #000;
  font-size: 17px;
}
.date_absolute {
  position: absolute;
  left: 325px;
  top: 10px;
  color: #000;
  font-size: 15px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c43e56 !important;
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 1rem;
}

.card-title {
  margin-bottom: 0 !important;
  padding: 1rem;
  font-size: 18px;
  font-weight: 500;
}

.card-title .btn-right {
  width: fit-content;
  float: right;
}

.card-title .btn-right .btn svg {
  width: 11px;
  margin-right: 1rem;
}

.card-title .btn-right .btn {
  margin-left: 10px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-weight: 400;
}

.table thead {
  background: #ffd6d6;
  color: #510000;
  font-weight: 300 !important;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0.1px solid #ffd6d6 !important;
}
.cr {
  background-color: #c43e56 !important;
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgb(196 62 86 / 15%) !important;
}
.mt-4 {
  margin-top: 20px;
}
.spading {
  margin-top: 20px;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  background-color: #ffd6d6 !important;
}
.tablecollabse-card {
  word-wrap: break-word;
  background-color: #f7f7f7;
  border-radius: 0px;
  box-shadow: none;
  border: none;
  margin: auto;
  padding: 0 2rem;
}

.collabse-tr:hover {
  color: #212529;
  background-color: transparent !important;
}

.collabse-tr td {
  padding: 0 !important;
  border: none !important;
}

.tablecollabse-card td {
  padding: 0.75rem !important;
  vertical-align: top;
  border-top: 1px solid #f0f0f0 !important;
}

.tablecollabse-card .table {
  margin-bottom: 0rem !important;
}

.btn {
  border-radius: 10px !important;
  padding: 8px 18px !important;
}

.btn-primary {
  color: #fff;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #ad2e45 !important;
  border-color: #ad2e45 !important;
}
.table_shadow {
  box-shadow: 0px 0px 10px 0px #ffd6d6;
}
.stock_div .table-responsive {
  overflow-y: scroll;
  height: 400px;
}
.edit_class .btn-group-sm.dropdown,
.edit_class .actionBtn.btn {
  padding: 10px !important;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
  box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
}

.min-screenheight {
  min-height: 87vh;
}

.modal-content {
  border: none !important;
}

.modal-header {
  background: #c43e56 !important;
  color: #fff;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  display: flex;
}

.modelradio {
  /* margin-left: auto; */
  display: block;
  width: inherit;
  position: relative;
}

.modal-body .table {
  margin-bottom: 0 !important;
}
.rns {
  cursor: pointer;
}
.failed {
  cursor: pointer;
}
.gt {
  fill: #c43e56;
}
.form-control {
  height: calc(1.5em + 0.75rem + 12px) !important;
  margin-bottom: 20px;
  color: #000 !important;
  background-color: #f2f2f2 !important;
  border: 0 !important;
  border-radius: 10px !important;
}
.hu {
  cursor: pointer;
}
.form-group {
  margin-bottom: 1.5rem !important;
}
.hoverd_box:before {
  width: 58px !important;
  content: "" !important;
  height: 48px !important;
}
.hoverd_box:after {
  height: 19px !important;
  content: "";
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 21px;
  right: 24px;
  width: 12px !important;
  background-image: url("./images/arr.png");
}
.hoverd_box:hover:before {
  background-color: #b6b6b6 !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #878787 !important;
  opacity: 1;
  font-size: 16px;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #878787 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #878787 !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 16px;
}

.col-form-label {
  padding-top: calc(0.8rem + 1px) !important;
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  font-size: 16px !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c43e56 !important;
  outline: 0;
  box-shadow: none !important;
}

.collapse-title h4 {
  font-size: 18px;
  color: #c43e56;
  cursor: pointer;
}

.my-collapse {
  padding: 1rem;
  border-bottom: 1px #c43e56 solid;
}

.form-group {
  margin-bottom: 2rem !important;
}

.input-group input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group-text {
  color: #fff !important;
  border-radius: 10px !important;
  min-width: 60px;
  justify-content: center;
  margin-left: -6px;
  z-index: 9;
  background-color: #b6b6b6 !important;
}

.item-center {
  display: flex;
  align-items: center;
}

.portfolio-summary h4 {
  background: #ffd6d6;
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 500;
  color: #510000;
  border-radius: 10px 10px 0px 0px;
  text-align: left;
}

.portfolio-details {
  background: #f7f7f778;
  margin: 10px;
  border-radius: 16px;
  padding: 20px 30px !important;
}

.portfolio-details h5 {
  text-align: left;
  color: #c43e56;
  font-weight: 500;
  text-decoration: none;
  font-size: 15px;
}

.portfolio-details ul {
  list-style: none;
  padding-left: 0;
  border-bottom: 1px solid#e8e1e1;
  margin-bottom: 12px !important;
}

.portfolio-details ul li {
  width: 100%;
  display: block;
  line-height: 20px;
}
.portfolio-details ul li:first-child {
  color: #585858;
  font-size: 13px !important;
}
.portfolio-details ul li:last-child {
  color: #585858;
  font-size: 15px;
  font-weight: 500;
}
.portfolio-details ul li span {
  color: #585858;
  font-weight: 500;
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
}

.multi-sublist li span {
  border-bottom: 1px #efefef solid;
  margin-bottom: 10px;
}

.multi-sublist li a {
  color: #c03e55 !important;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: fit-content !important;
  margin: auto !important;
  border-radius: 66px !important;
  border: 1px #c03e55 solid !important;
  overflow: hidden !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #c03e55 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: 1px #c03e55 solid !important;
  padding: 0.6rem 1rem !important;
  color: #c03e55 !important;
  font-weight: 500;
  cursor: pointer;
  font-size: 15px;
}

@media only screen and (max-width: 800px) {
  .nav {
    display: block !important;
    border-radius: 10px !important;
  }
  .nav-tabs .nav-link {
    border-right: 1px transparent solid !important;
  }
}

@media only screen and (max-width: 992px) {
  .input-group {
    margin-bottom: 1rem;
  }
  .custom-control {
    margin-bottom: 1rem;
  }
  .collapse-title {
    margin-bottom: 1rem;
  }
}

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin: 0 !important;
    border: 1px #d9d9d9 solid !important;
    border-radius: 62px !important;
    overflow: hidden;
    padding: 3px;
    position: relative;
    right: 15px;
  }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: transparent !important;
}

.page-link {
  position: relative;
  display: block;
  padding: 6px 11px !important;
  margin-left: -1px;
  line-height: 1.25;
  color: #717171 !important;
  background-color: #fff;
  border: 1px solid #dee2e600 !important;
}

.cusnavigation {
  width: fit-content !important;
  margin-left: auto !important;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.page-link:hover {
  z-index: 2;
  color: #c43e56 !important;
  text-decoration: none;
  background-color: #c43e5666 !important;
  border-color: #dee2e6;
  border-radius: 47px !important;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
  border-radius: 50px;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 17px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.dropdown .nav-link {
  color: #fff;
  cursor: pointer;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #c43e56 !important;
  border: none;
}

.signBg {
  background-image: url("images/mike-unsplash.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.cus-link {
  color: inherit !important;
  text-decoration: none !important;
  background-color: transparent;
}

.cus-link:hover {
  color: inherit !important;
  text-decoration: none !important;
  background-color: transparent;
}

.signForm-bg {
  background: #fff;
  padding: 3rem;
}

.signForm-bg img {
  margin-bottom: 2rem !important;
}

.signForm-bg h4 {
  margin-bottom: 2rem;
  color: #c43e56;
}

.signForm-bg button {
  margin-top: 3rem !important;
}

.update-avatar {
  width: 105px;
  height: 105px;
  border-radius: 60px;
  object-fit: cover;
}

.update-avatar-btn {
  background: #c03e55 !important;
  padding: 10px 14px !important;
  position: relative !important;
  display: block !important;
  left: 4rem !important;
  top: -35px !important;
  border: none !important;
}

.update-avatar-btn svg {
  width: 16px;
  fill: #fff;
}

/*Slider  */

.MuiSlider-thumb {
  color: #c03e55 !important;
}

.MuiSlider-rail {
  height: 3px !important;
  background-color: #4e4e4e48 !important;
}

.MuiSlider-track {
  height: 3px !important;
  background-color: #c03e55 !important;
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgb(192 62 85 / 20%) !important;
}

.MuiSlider-marked {
  margin-bottom: 20px;
  margin-top: 2rem !important;
}

.MuiSlider-mark {
  background-color: #c03e556b !important;
}

.PrivateValueLabel-circle-4 {
  width: 26px !important;
  height: 26px !important;
  top: 12px;
  left: 3px;
  position: absolute;
}

.custom-control-label:before {
  background-color: white !important;
  border: 2px #696969 solid !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c03e55 !important;
  border: 2px #c03e55 solid !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: #c03e55 !important;
}

/** focus shadow pinkish **/

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem transparent !important;
}

/* This is the default state */

.custom-radio .custom-control-label::before {
  background-color: white !important;
}

/* This is the checked state */

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #c03e55 !important;
  /* green */
  border-radius: 50%;
  border: none !important;
}

/* active state i.e. displayed while the mouse is being pressed down */

.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: #fff !important;
  background-color: #c03e55 !important;
  /* red */
}

/* the shadow; displayed while the element is in focus */

.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem transparent !important;
  /* pink, 25% opacity */
}

.collapse-plus {
  background: #c03e552b;
  border-radius: 48px;
  width: 25px;
  height: 25px;
  border: 1px #c03e55 solid;
  display: block;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
  left: -4px;
  top: -4px;
}

.collapse-plus img {
  width: 11px;
}

.direct-holdings {
  width: 100%;
  margin: auto;
  margin-top: 15px;
  /* display: flex; */
}

.text-end {
  text-align: end;
}

.modal-body .searchBtn {
  border-bottom: 1px #e2e2e2 solid;
  border-top: 1px #e2e2e2 solid;
  border-right: 1px #e2e2e2 solid;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

@media only screen and (max-width: 992px) {
  .direct-holdings {
    margin-top: -15px;
  }
  .navbar .nav-item .nav-link {
    display: block !important;
    text-align: center;
    padding: 1rem !important;
    border-bottom: 1px #ffffff17 solid !important;
  }
  .navbar-toggler {
    padding: 4px 7px !important;
    font-size: 1.25rem;
    line-height: 1;
    background-color: #c43f57 !important;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .navbar .nav-item .nav-link span svg {
    margin-right: 10px;
  }
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: auto !important;
    display: block !important;
    width: fit-content !important;
    margin-bottom: 1rem !important;
    margin-top: 2rem !important;
  }
  .navbar-toggler svg {
    width: 23px;
    fill: #fff;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    border-radius: 50px !important;
    padding: 7px 9px !important;
    font-size: 13px !important;
  }
  .cusnavigation {
    margin-right: auto;
  }
}

@media only screen and (max-width: 600px) {
  .card-title .btn-right .btn {
    margin-left: 6px !important;
    margin-top: 1rem !important;
    float: right !important;
  }
  .card-title .btn-right .searchBtnTable {
    margin-left: 6px !important;
    margin-top: -1px !important;
    float: right !important;
  }
}

@media only screen and (max-width: 458px) {
  .btn-right .input-group {
    margin: auto;
    display: block;
    left: 10%;
    margin-top: 0.5rem;
  }
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-right .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  float: left;
  width: fit-content;
  right: 0px;
}

.btn-right .form-control {
  height: 43px !important;
  z-index: 0 !important;
  border-radius: 10px !important;
  background: #fff !important;
}

.btn-right .searchBtnTable {
  margin: 0;
  position: absolute !important;
  top: 0px;
  right: 15px;
  z-index: 1 !important;
}

.btn-right .searchBtnTable img {
  width: 38px;
}

.actionBtn svg {
  fill: #c43e56;
}

.modelsearch-btn {
  border: 1px #dedede solid !important;
  border-radius: 32px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding: 0 1.5rem !important;
}

.inputgroup-dropdown-btn {
  background: transparent !important;
  border: none !important;
  color: #333 !important;
}

.btn-group .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid !important;
  border-right: 0.3em solid #ab1a1a00 !important;
  border-bottom: 0;
  border-left: 0.3em solid transparent !important;
  color: #333 !important;
  position: relative;
  top: 5px;
}

.modal-header .close {
  /* display: none; */
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.btn-outline-primary {
  color: #c43e56 !important;
  border-color: #c43e56 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #c43e56 !important;
  border-color: #c43e56;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
}

.table-scroll {
  max-height: 55vh;
  overflow: auto;
  position: relative;
  padding-bottom: 3rem;
  cursor: pointer;
}

.settingTab button {
  background: #fcfcfc;
  border: 1px #eaeaea solid;
  color: #4a4a4a;
  margin-bottom: 2rem;
}

.settingTab button.active {
  color: #fff !important;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
}

.settingTab .btn-link:hover {
  text-decoration: none;
  color: #fff !important;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: none !important;
}

.valueBox {
  border: 1px #dcdcdc solid;
  box-shadow: 0 6px 13px #00000005;
  padding: 1rem;
  padding-bottom: 4rem;
}

.signBtn {
  position: relative;
}

.signBtn .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  position: absolute;
  left: 9px;
  top: 4px;
}

.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #c43e56 !important;
  border-color: #c43e56 !important;
}

.input-group-text select {
  background: transparent !important;
  border: none !important;
  padding: 0;
  margin: 0;
  height: fit-content !important;
  color: #fff !important;
}

.input-group-text select.form-control:focus {
  color: #495057;
  background-color: #fff0;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.summaryTopPosition {
  position: relative;
}

@media only screen and (max-width: 992px) {
  .summaryTopPosition .nav {
    margin-left: 0px !important;
  }
}

.summaryBtnPositoin {
  position: absolute;
  top: 0;
  right: 0;
}

.summaryBtnPositoin a + button {
  margin-left: 10px;
}

/*  */

/*****************************************
  upload button styles
******************************************/

.file-upload {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  border: 1px #c43e56 dashed;
  padding-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.file-upload__label {
  display: block;
  padding: 1em 2em;
  color: #c43e56;
  background: #ffffff;
  border-radius: 0.4em;
  transition: background 0.3s;
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.kt-widget__content {
  padding: 1.9rem 0 2.1rem 0;
}

.kt-widget__info {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px #e0e0e0 solid;
}

.kt-widget__label {
  color: #c72e2c;
  font-weight: 500;
  width: 60%;
}

.kt-widget__data {
  color: #444548;
  font-weight: 600;
  text-align: end;
}

.required:after {
  color: #c43e56;
  content: " *";
  display: inline;
}

.react-tel-input .form-control {
  width: 100% !important;
}

@media only screen and (max-width: 992px) {
  .kt-widget__info {
    font-size: 14px;
    display: block;
  }
  .kt-widget__label {
    display: block;
  }
}

.editProOption {
  position: relative;
  margin: auto;
  text-align: center;
  display: grid;
  justify-content: center;
}

.editProOption label {
  display: inline-block;
  background-color: #c43e56 !important;
  color: white;
  padding: 4px 12px;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}
.informicon {
  margin-left: 10px;
  font-size: 19px;
  color: grey;
}
.padingconst {
  padding-top: 25px;
}
.cardconst {
  padding: 20px;
}
.editProOption input {
  position: absolute;
  bottom: 0;
  opacity: 0;
  padding: 14px;
}
.bondcard {
  padding-left: 10px;

  font-size: 11px;
}
.tablecontent {
  font-size: 10px;
}
.ModifyBtn {
  /* width: fit-content;
    position: fixed;
    margin-left: auto;
    z-index: 10000000;
    bottom: 1rem;
    right: 1rem;
    display: flex; */
  width: fit-content;
  margin: 1rem auto;
  z-index: 10000000;
  display: flex;
}

.ModifyBtn button {
  margin-left: 0px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(0.2em + 0.9rem) center !important;
}

.table-btn-section {
  display: flex;
  align-items: center;
}

.table-btn-section .dropdown svg {
  fill: #c43e56;
}

.table-btn-section .dropdown {
  padding: 8px 18px !important;
}

.table td {
  vertical-align: inherit !important;
  color: #585858;
}
.padingfund {
  padding-left: 12px !important;
}
.switch.btn {
  min-width: 3.7rem;
  min-height: calc(1em + 0.75rem + 0px) !important;
}
.btn-light {
  border-radius: 8px !important;
}
.is {
  padding-left: 12px;
}
.recharts-legend-wrapper {
  padding-top: 10px;
}
.overlayCont {
  position: fixed;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 9;
}
.overlayNotification {
  height: 89vh;
  width: 250px;
  background: #fff;
  right: 0;
  position: absolute;
  overflow-y: auto;
}
.none-bell a {
  color: #fff !important;
}
.custom-sticky {
  position: sticky !important;
  top: 0;
  z-index: 9999999;
}
.none-bell {
  display: none;
}
.btn-read {
  padding-top: 4px;
  text-align: right;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.bobble,
.bobble-mobile {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: 10px;
}
.mobile-none.nav-item {
  position: relative;
}
.bobble:after {
  position: absolute;
  content: "";
  display: block;
  background: #c43e56;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: -139px;
  top: 18px;
}
.bobble-mobile:after {
  position: absolute;
  content: "";
  display: block;
  background: #c43e56;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 1px;
  left: 8px;
}
.emty-tx {
  opacity: 0%;
}
.input-border:focus {
  outline: none;
}
.input-border:read-only {
  background-color: #e9e9e9;
}
.input-border {
  width: 100%;
  line-height: 1.5;
  opacity: 1;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 30px;
  height: 45px !important;
  padding: 5px 10px;
  font-size: 16px;
}
.h-label label {
  line-height: 13px;
  font-size: 13px;
  margin: 0px;
}
.red-i {
  color: red;
  font-size: 25px;
}
.green-i {
  color: green;
  font-size: 25px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .none-bell {
    display: block;
    padding-left: 0px !important;
  }
  .mobile-none {
    display: none;
  }
}
.is {
  padding-left: 30px;
}
.alertCont {
  cursor: pointer;
}

.color_ac {
  color: #ac0000 !important;
}
.color_26 {
  color: #26262e;
}
.bg_26 {
  background: #26262e;
}
.bg_f2 {
  background: #f2f2f2;
}
.bg_d6 {
  background: #ffd6d6;
}

.text_16_c4 {
  color: #c43e56;
  font-size: 16px;
}
.text_16_51 {
  color: #510000;
  font-size: 16px;
}
.sidebarCont {
  width: 240px;
  min-height: 100vh;
  position: fixed;
}
.contentWrapper {
  margin-left: 240px;
}
.side_ul {
  list-style: none;
}
.side_ul li a {
  color: #c4bcbc;
  font-size: 14px;
  line-height: 46px;
  padding-left: 10px;
}
.side_ul li.active a {
  color: #fff;
  background: #c43e56;
  font-size: 16px;
  line-height: 46px;
}
.side_ul li a:hover {
  text-decoration: none;
}
.st_card {
  border: none !important;
  box-shadow: none;
  border-radius: 0 !important;
}
.stick_bottom {
  position: fixed;
  bottom: 20px;
}
.list_cli {
  background: #ffd6d6;
  border-radius: 10px 10px 0px 0px;
  display: inline-block;
  padding: 15px 12px;
  color: #510000;
}
.portfolio-summary {
  box-shadow: 0px 0px 20px 0px #ac000017;
  border-radius: 10px 10px 0px 0px;
  background: #fff;
}
.overflow_hight {
  overflow-y: auto;
  max-height: 500px;
  margin-right: 10px;
  padding-bottom: 20px;
}
.Con_hight {
  max-height: 100vh !important;
  padding-bottom: 0px !important;
}
.per_hight {
  max-height: 100vh !important;
  padding-bottom: 0px !important;
}
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #c43e56;
}
.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #ffd6d6;
  border-radius: 10px;
}
.overflow_hight::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #ffd6d6;
  border-radius: 10px;
}
.bont_scroll thead {
  display: table;
}
.table-responsive {
  /* display: block; */
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.bond_table {
  display: table;
  background-color: #c43e56;
  color: white;
}
.bond_table thead th {
  border: none !important;
  vertical-align: top !important;
}
.fund_table thead {
  display: table;
}
.stock_scroll thead {
  display: table;
  position: sticky;
  top: 0;
}
.chart_flow .dropdown-menu.show {
  width: 100%;
}
.fix_auto {
  overflow-x: hidden;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: right;
  width: 50%;
}
.recharts-legend-item-text {
  display: true;
  color: black;
}

.pds {
  margin-left: 50px;
}
.pds2 {
  margin-left: 22px;
}
.use {
  color: black;
}

.recharts-tooltip-wrapper {
  color: black;
}
.overflow_hight::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c43e56;
}
.port_btn {
  background-color: #a7a7a7 !important;
  border: none !important;
  height: 45px;
  width: 100%;
}
.table_trans_line {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #c43e56;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 5%);
}
.one_eight {
  font-size: 18px;
}
.single_table_trans thead {
  background: transparent;
}
.table_trans_line .table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}
.table_trans_line .table-striped tbody tr:hover {
  background-color: transparent !important;
}
.h_350 .table-responsive {
  height: 400px;
}
.text_16_58 {
  color: #585858 !important;
  font-size: 16px;
}
.chart_reset_btn {
  position: absolute;
  color: #c43e56 !important;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
  left: 70px;
  z-index: 9;
}
.popup_radio {
  position: relative !important;
}
.single_table_trans tr td {
  padding-bottom: 8px !important;
  padding-top: 0px !important;
  background-color: transparent;
  border-bottom: none !important;
  font-size: 16px;
  color: #510000;
  border-radius: 8px;
  border: none !important;
  font-weight: 500;
}
.single_table_trans tr th {
  padding-top: 12px !important;
  padding-bottom: 0 !important;
  font-size: 14px;
  color: #510000;
  border-radius: 10px;
  border: none !important;
}
.single_table tr td,
.single_table tr th {
  background: transparent;
  border: none !important;
  line-height: 15px !important;
  height: 0 !important;
  vertical-align: unset !important;
}
.single_table {
  background: #ffd6d6;
  border-radius: 10px;
}
.single_table tr th {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  font-size: 14px;
  color: #510000;
  border-radius: 10px;
}
.single_table thead {
  background-color: transparent;
}
.single_table tr td {
  padding-bottom: 20px !important;
  padding-top: 3px !important;
  background-color: #ffd6d6;
  border-bottom: none !important;
  font-size: 16px;
  color: #510000;
}
#root {
  height: 100%;
  margin: 0px;
}

.example-container {
  height: 100%;
  background-color: rgb(185, 209, 253);
}

.panel-container {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button {
  margin: 2px;
}

.form-container {
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input-container {
  padding: 10px;
}
.summary_tabs li {
  width: 25%;
}
.summary_tabs {
  width: 100% !important;
  border-radius: 10px 10px 0px 0px !important;
  border: 1px solid #ffd6d6 !important;
}
.summaryTopPosition .summary_tabs li a {
  color: #585858 !important;
  font-size: 16px;
  border: none !important;
}
.summaryTopPosition .summary_tabs li a.active {
  color: #585858 !important;
  background-color: #ffd6d6 !important;
}
.w25 {
  max-width: 100px;
}
.summaryTopPosition .summary_tabs li {
  text-align: center;
  border-color: #ffd6d6 !important;
  border: 1px solid;
}
.line_btn {
  background: transparent;
  color: #c43e56;
  border: 2px solid#C43E56;
}
.ps4 {
  margin-right: 10px;
}
.break_tab {
  margin: 0 !important;
  border-radius: 10px 10px 0px 0px !important;
  border: 1px solid #c43e56 !important;
}
.break_table thead {
  background: #c43e56 !important;
  color: #fff;
}
.break_table thead th {
  border: none !important;
  vertical-align: bottom !important;
}
.form_border_0:focus {
  outline: 0 !important;
  border: 0 !important;
}
.form_border_0:before {
  border: 0 !important;
}
.margin_minus,
.margin_minus_bond {
  margin-bottom: 20px;
}
.margin_minus table input,
.margin_minus_bond table input {
  width: 115px;
  border: 1px solid #ffd6d6 !important;
  border-radius: 4px;
}
.margin_minus {
  margin-top: -25px;
}
.margin_minus thead {
  width: 100% !important;
}
.margin_minus tbody tr {
  width: 100% !important;
  display: block;
}
.chart_left {
  border: 1px solid #c43e56;
  border-radius: 10px;
  padding: 20px;
}
.summaryTopPosition .summarys_tabs li {
  text-align: center;
  border-color: #ffd6d6 !important;
  border: 1px solid;
}
.checked {
  display: block;
  margin-bottom: 15px;
}

.checked input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checked label {
  position: relative;
  cursor: pointer;
}

.checked label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #c43e56;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 14px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 6px;
}
.table-hover tbody tr:focus {
  color: #212529;
  background-color: rgb(196 62 86 / 15%) !important;
}
.table-focus tbody tr:focus {
  color: #212529;
  background-color: rgb(196 62 86 / 15%) !important;
}
.checked input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 19px;
  width: 8px;
  height: 18px;
  border: solid #c43e56;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.form_space .form-group {
  margin-bottom: 20px !important;
}
.bg_email {
  background: #ffe6e6;
  padding: 5px 6px;
  border-radius: 7px;
}
.form_space label {
  font-size: 15px !important;
  color: #585858 !important;
}
.form_space .flag-dropdown {
  border: none !important;
  border-radius: 10px !important;
  background-color: #f5f5f5;
}
.form_space .flag-dropdown .selected-flag:hover {
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
}
.port_li ul li {
  color: #585858 !important;
  font-size: 16px;
  padding: 10px;
  border-radius: 0;
  background: #fff;
  width: 33.333% !important;
}
.port_li ul li.react-tabs__tab--selected {
  background: #ffd6d6;
}
.port_li ul li:last-child {
  border-radius: 0px 10px 0px 0px !important;
}
.bg_red_img {
  background-image: url("./images/mike-unsplash.jpg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 100vh;
}
.bg_red_ght {
  height: 100%;
}
.board_white {
  background-color: #fff !important;
  border-radius: 20px;
  padding: 0px 22px 22px;
}
.valuationCheck {
  display: inline-block;
  width: auto;
  margin-right: 7px;
  position: relative;
  vertical-align: middle;
}

.form-check-input {
  right: 0;
  top: -6px;
}
.form-check-input {
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input {
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:checked ~ .checktitle {
  color: #ac0000;
}
.form-check-input ~ .checktitle {
  color: #510000;
  padding: 10px 30px;
  display: inline-block;
  background: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
}
.container_radio input:checked ~ .checkmark {
  background-color: #fff;
}
.form-check-input:checked ~ .checkmark {
  color: #ac0000;
}
.form-check-input ~ .checkmark {
  color: #aaa;
}

.form-check-input:checked ~ .checkborder {
  left: 0;
  top: 9px;
  border: 2px solid #ac0000;
  border-radius: 5px;
  width: 100%;
  height: 44px;
  display: block;
  position: absolute;
}
.form-check-input ~ .checkborder {
  left: 0;
  top: 9px;
  border: 2px solid #e9e9e9;
  border-radius: 5px;
  width: 100%;
  height: 44px;
  position: absolute;
}
.container_radio {
  display: block;
  position: relative;
  padding: 8px 0px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* Hide the browser's default radio button */
.container_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 22px;
  left: 8px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid;
}

/* On mouse-over, add a grey background color */
.container_radio:hover input ~ .checkmark {
  background-color: #fff !important;
}

/* When the radio button is checked, add a blue background */
.container_radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_radio input:checked ~ .checkmark:after {
  display: block;
}
.imgmar {
  margin-top: 20px;
}
.inr {
  color: black !important;
}
/* Style the indicator (dot/circle) */
.container_radio .checkmark:after {
  top: 1px;
  left: 1px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ac0000;
}
.link_view {
  font-size: 15px;
  color: #c43e56;
  text-decoration: underline;
}
.btn_cont {
  color: #fff;
  background-color: #a7a7a7 !important;
  border-color: #a7a7a7 !important;
}
.bg_6d6 {
  background: #ffd6d6 !important;
}
.icon_c43 {
  color: #c43e56;
  position: absolute;
  top: 13px;
  right: 15px;
  font-size: 22px;
}
.red_relative {
  position: relative;
}
a {
  cursor: pointer;
}
a:hover {
  text-decoration: none !important;
}
.side_ul li a.active {
  background: #c43e56;
  color: #fff !important;
  display: block;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .none-bell,
  .navbar-toggler,
  .menu_none,
  .navbar-brand,
  .stick_bottom {
    display: none !important;
  }
  .sidebarCont {
    width: 40px;
  }
  .contentWrapper {
    margin-left: 40px;
  }
  .cardconst {
    padding: 10px;
  }
  .summary_tabs li {
    width: auto;
  }
  .break_tab {
    width: 100% !important;
  }
  .chart_flow .recharts-wrapper {
    width: 100% !important;
  }
  .flex-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  .odr_1 {
    order: 1;
    margin-top: 0px !important;
  }
  .odr_2 {
    order: 2;
  }
}
@media only screen and (min-width: 378px) and (max-width: 1024px) {
  .sidebarCont {
    width: 50px;
  }
  .contentWrapper {
    margin-left: 50px;
  }
  .none-bell,
  .navbar-toggler,
  .menu_none,
  .navbar-brand,
  .stick_bottom {
    display: none !important;
  }
  .summaryTopPosition .summary_tabs li {
    width: 100%;
    text-align: left;
  }
  .break_tab li {
    display: inline-block;
  }
}
.dropdownbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -2%);
  width: 100%;
}

.dropdownbox select {
  background-color: #0563af;
  color: white;
  padding: 12px;
  border: none;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.dropdownbox::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: #b6b6b6;
  pointer-events: none;
  border-radius: 10px;
}

.dropdownbox:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.dropdownbox select option {
  padding: 30px;
}
.recharts-wrapper {
  margin: auto !important;
}
.custom_s_width .input-group-text,
.custom_s_width .dropdownbox:before {
  width: 63px !important;
}
.Bond-cancel {
  width: 140px;
}
.Bond-save {
  width: 140px;
  /* margin:0px 54px; */
}
.bondModel h5 {
  width: 100%;
}
.modify-button {
  margin-left: 300px;
  align-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
.Remove-btn {
  margin-top: 10px;
}
.equities-constraints {
  visibility: hidden;
}
.bond-modify {
  width: 150px;
}
